<template>
  <div id="main">
    <banner
      title="Paket kamu stuck? kamu bisa ajukan klaim stuck sesuai batas pengajuan klaim berikut :"
      :message="['Idexpress & Ninja: 15 - 30 hari*', 'JNE, Sicepat, SAP & J&T: 15 - 60 hari*']"
    />
    <b-row class="mb-1 justify-content-between align-items-center">
      <b-col>
        <div
          class="p-1 font-bold rounded-lg"
          style="border: 1px solid black;max-width: 300px;"
        >
          Total dalam perjalanan : <span class="text-info">{{ totalKirim }}</span><br>
          <span
            class="d-inline-block"
            style="margin-top: 10px;"
          >
            Terkena kendala :<span class="text-danger">{{ totalProblem }}</span>
            <b-button
              class="rounded-lg my-auto"
              :variant="isProblem ? 'danger' : 'outline-danger'"
              style="padding: 2px 6px!important;margin-left: 5px;"
              @click="filterProblem"
            >Lihat Kendala</b-button>
          </span>
        </div>
      </b-col>
      <b-col
        md="5"
        class="d-flex"
      >
        <b-form-input
          v-model="formSearch"
          type="search"
          class="form-search mr-2"
          placeholder="Cari Pelanggan atau Resi"
          @input="fetchData(formSearch)"
        />
        <b-icon-search class="icon-search" />
        <div style="position: relative;">
          <img
            id="buttonFilter"
            src="https://storage.googleapis.com/komerce/assets/svg/filter-icon-orange.svg"
            class="cursor-pointer"
          >
          <b-badge
            variant="primary"
            style="position: absolute; border-radius: 1.358rem; top: -15%; right: 0%;"
          >
            {{ totalFilterDataOrder }}
          </b-badge>
        </div>
        <b-popover
          id="popoverFilter"
          target="buttonFilter"
          triggers="click"
          placement="bottomleft"
        >
          <label>Tanggal</label>
          <b-row>
            <b-col md="6">
              <flat-pickr
                v-model="startDate"
                class="form-control"
                placeholder="Mulai Dari"
                :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d',}"
                @input="setFilterDate"
              />
            </b-col>
            <b-col md="6">
              <flat-pickr
                v-model="endDate"
                class="form-control"
                placeholder="Sampai Dengan"
                :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d', minDate: startDate}"
                @input="setFilterDate"
              />
            </b-col>
          </b-row>
          <label class="mt-1">Gudang</label>
          <v-select
            v-model="addressId"
            :options="filterWarehouses"
            :reduce="(option) => option"
            label="name"
            @input="setFilterAddress"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <img
                v-if="option.is_kompack === 1"
                src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                class="mr-[5px] w-[14px] h-[14px] d-inline mb-[3px]"
              >
              {{ option.name }} {{ option.unsubscribe === 1 ? '(Unsubscribe)' : '' }}
            </template>
            <span
              slot="no-options"
              @click="$refs.select.open = false"
            />
          </v-select>
          <label class="mt-1">Produk</label>
          <v-select
            v-model="productName"
            :options="filterProducts"
            :reduce="(option) => option.id"
            label="product_name"
            @input="setFilterProduct"
          >
            <span
              slot="no-options"
              @click="$refs.select.open = false"
            />
          </v-select>
          <label class="mt-1">Metode Pembayaran</label>
          <v-select
            v-model="paymentMethod"
            :options="['COD', 'BANK TRANSFER']"
            @input="setFilterPayment"
          />
          <b-row class="mx-auto mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click.prevent="resetFilter()"
            >
              Reset
            </b-button>
            <b-button
              variant="primary"
              @click.prevent="fetchData()"
            >
              Terapkan
            </b-button>
          </b-row>
        </b-popover>
      </b-col>
    </b-row>
    <b-table
      id="table-order"
      responsive
      show-empty
      empty-text="Tidak ada data untuk ditampilkan."
      :items="items"
      :fields="fields"
      :busy="loadingTable"
    >
      <template #cell(order_date)="data">
        <div
          style="min-width:150px!important;"
        >
          <p class="font-bold text-[14px] text-[#222222] mb-0">
            {{ DAY_MONTH_YEAR(data.item.order_date) }}
          </p>
          <p class="font-semibold text-[12px] text-[#828282] mb-0">
            {{ TIME(data.item.order_date) }}
          </p>
          <span class="d-flex items-center text-secondary font-normal">
            <img
              v-if="data.item.is_kompack === 0"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/house-2.svg"
              class="mr-[5px] w-[14px] h-[14px]"
            >
            <img
              v-if="data.item.is_kompack === 1"
              src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
              class="mr-[5px] w-[14px] h-[14px]"
            >
            {{ data.item.warehouse_name }}
          </span>
        </div>
      </template>
      <template #cell(customer_name)="data">
        <span class="font-bold">{{ data.item.customer_name }}</span><br>
        <div
          v-if="data.item.is_komship === 1 || data.item.is_kompack === 1"
          class="d-flex items-center gap-2 mt-[4px]"
        >
          <img
            :src="data.item.shipment_image_path"
            class="w-14"
          ><span>{{ data.item.shipment_alias }}</span>
        </div>
      </template>
      <template #cell(product)="data">
        <div v-if="data.item.product[0]">
          <div
            v-for="(itemProduct, index) in data.item.product.slice(0, 1)"
            :key="index+1"
            class="d-flex"
            style="min-width:160px!important"
          >
            <img
              v-if="data.item.product[0].product_image === null || data.item.product[0].product_image === ''"
              class="image-product"
              src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
            >
            <img
              v-else
              class="image-product"
              :src="data.item.product[0].product_image"
              :alt="data.item.product[0].product_image"
            >
            <div>
              <div style="margin-left:5px;">
                <span class="d-flex font-bold">{{ data.item.product[0].product_name }}</span>
                <span
                  v-if="itemProduct.variant_name !== '0'"
                  class="text-primary"
                >{{ itemProduct.variant_name }}</span>
              </div>
              <div
                v-if="itemProduct.is_bundling === 1"
                class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer mt-[5px] w-[7rem]"
                @click="$bvModal.show(`bundling-${String(data.index)}-${String(itemProduct.detail_id)}`)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                  alt="bundling"
                  width="16"
                >
                <div>Bundling</div>
              </div>
            </div>
            <div
              class="font-bold ml-auto"
            >
              x{{ itemProduct.qty }}
            </div>
            <modal-bundling-quantity
              v-if="itemProduct.is_bundling === 1"
              :data="itemProduct"
              :index="data.index"
            />
          </div>
          <div v-if="data.item.product.length > 1">
            <b-collapse :id="'collapse-'+data.item.order_id">
              <div
                v-for="item in data.item.product.slice(1, data.item.product.length)"
                :key="item.order_id"
                class="d-flex mt-1"
                style="min-width:160px!important"
              >
                <img
                  v-if="item.product_image === null || item.product_image === ''"
                  class="image-product"
                  src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                >
                <img
                  v-else
                  class="image-product"
                  :src="item.product_image"
                  :alt="item.product_image"
                >
                <div>
                  <div style="margin-left:5px;">
                    <span class="d-flex font-bold">{{ item.product_name }}</span>
                    <span
                      v-if="item.variant_name !== '0'"
                      class="text-primary"
                    >{{ item.variant_name }}</span>
                  </div>
                  <div
                    v-if="item.is_bundling === 1"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer mt-[5px] w-[7rem]"
                    @click="$bvModal.show(`bundling-${String(data.index)}-${String(item.detail_id)}`)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                      alt="bundling"
                      width="16"
                    >
                    <div>Bundling</div>
                  </div>
                </div>
                <div
                  class="font-bold ml-auto"
                >
                  x{{ item.qty }}
                </div>
                <modal-bundling-quantity
                  v-if="item.is_bundling === 1"
                  :data="item"
                  :index="data.index"
                />
              </div>
            </b-collapse>
          </div>
        </div>
      </template>
      <template #cell(grand_total)="data">
        <span class="d-flex">
          Rp {{ formatNumber(data.item.grand_total) }}
          <img
            v-if="data.item.order_notes !== '0' && data.item.order_notes !== '' && data.item.order_notes !== null"
            :id="`infoNote` + data.item.order_id"
            src="https://storage.googleapis.com/komerce/assets/icons/info-order-notes.svg"
            class="ml-auto cursor-pointer"
            style="max-width:20px"
          >
          <b-popover
            triggers="hover"
            :target="`infoNote` + data.item.order_id"
            placement="bottomright"
          >
            {{ data.item.order_notes }}
          </b-popover>
        </span>
        <span
          v-if="data.item.payment_method === 'COD'"
          class="text-primary"
        >
          COD
        </span>
        <div
          v-else-if="data.item.payment_method === 'BANK TRANSFER'"
          class="d-flex"
        >
          <span class="text-primary">Transfer</span>
          <img
            v-if="data.item.bank !== '0'"
            id="popoverInfoBank"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            class="icon-info"
          >
          <b-popover
            triggers="hover"
            target="popoverInfoBank"
            placement="bottomleft"
          >
            <label>Nama Bank:</label><br>
            <span class="font-bold">{{ data.item.bank }}</span><br>
            <label>No Rekening:</label><br>
            <span class="font-bold">{{ data.item.bank_account_no }}</span><br>
            <label>Pemilik Rekening:</label><br>
            <span class="font-bold">{{ data.item.bank_account_name }}</span><br>
          </b-popover>
        </div>
      </template>
      <template #cell(airway_bill)="data">
        <div class="inline-flex items-center cursor-pointer">
          <span
            v-b-tooltip.hover.top="'Klik untuk copy resi'"
            class="colorActive"
            @click.prevent="copyResi(data.item.airway_bill)"
          >
            {{ data.item.airway_bill }}
          </span>
          <img
            :id="`iconInfo` + data.item.order_id"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            class="copy-resi"
          >
          <div v-if="data.item.last_history_awb !== null">
            <b-popover
              triggers="hover"
              :target="`iconInfo` + data.item.order_id"
              placement="topleft"
            >
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/car.svg"
                  alt="Komerce"
                >
                <div class="ml-1">
                  <span>{{ handleDateTransfer(data.item.last_history_awb.date) }}</span>
                  <br>
                  <strong>{{ data.item.last_history_awb.desc }}</strong>
                </div>
              </div>
            </b-popover>
          </div>
        </div>
        <div>
          <b-button
            v-if="data.item.is_problem === 1"
            variant="danger"
            class="p-[4px!important] mt-[2px] rounded-[8px!important] font-[12px] disabled:bg-[#C2C2C2!important] disabled:border-[#C2C2C2!important] disabled:active:bg-[#C2C2C2!important]"
            :disabled="data.item.is_problem_accessible === false"
            @click="openTicketing(data.item.ticket_id)"
          >
            Kendala
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle-white.svg"
              alt="info"
              class="inline-block ml-[4px]"
            >
          </b-button>
        </div>
      </template>
      <template #cell(details)="data">
        <div class="d-flex flex-column justify-content-center text-center">
          <div class="d-flex flex-wrap align-items-center mb-1">
            <b-button
              variant="none"
              class="button-detail d-flex"
              :to="{ name: $route.meta.routeDetail, params: { order_id: data.item.order_id } }"
            >
              Lihat Detail
            </b-button>
            <b-button
              v-if="data.item.force_claim_available"
              variant="flat-dark"
              class="btn-icon p-0"
            >
              <b-img
                :id="`hover-force-delivered-` + data.item.order_id"
                class="rotate-90"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
              />
              <b-popover
                :target="`hover-force-delivered-` + data.item.order_id"
                triggers="hover"
                placement="top"
                :custom-class="isActive ? 'bg-primary text-white' : ''"
              >
                <div
                  type="button"
                  class="d-flex w-[25rem]"
                  @mouseenter="isActive = true"
                  @mouseleave="isActive = false"
                  @click="forceDelivered(data.item.order_id)"
                >
                  <span :class="isActive ? 'text-white' : ''">Laporan paket sudah diterima</span>
                  <b-img
                    :id="`tooltip-force-${data.index}`"
                    class="ml-[5px]"
                    :src="isActive ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-question.svg' : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-question.svg'"
                  />
                  <b-tooltip
                    :target="`tooltip-force-${data.index}`"
                    triggrers="hover"
                    variant="light"
                  >
                    <span>Laporkan jika Paket Kiriman kamu sebenarnya sudah Diterima namun status masih Dikirim</span>
                  </b-tooltip>
                </div>
              </b-popover>
            </b-button>
          </div>
          <div
            v-if="data.item.claim_data.some(item => item.claim_type === 'paksa_diterima')"
            :id="`status-claim-tooltip-${data.index}`"
            class="px-2"
          >
            <div
              v-if="data.item.claim_data.some(item => item.claim_type === 'paksa_diterima')"
              :class="claimStatusClass(data.item.claim_data.find(item => item.claim_type === 'paksa_diterima').claim_status)"
              class="text-[12px] text-center w-[10rem]"
            >
              Laporan Diterima {{ labelClaims(data.item.claim_data.find(item => item.claim_type === 'paksa_diterima').claim_status) }}
            </div>
            <b-popover
              :target="`status-claim-tooltip-${data.index}`"
              triggers="hover"
              placement="top"
            >
              <span style="color: #828282">
                {{ showLabelStatusClaim(data.item.claim_data.find(item => item.claim_type === 'paksa_diterima')) }}
              </span>
            </b-popover>
          </div>
          <div
            v-if="data.item.product.length > 1"
          >
            <b-button
              v-b-toggle="'collapse-'+data.item.order_id"
              class="buttonCollapse px-0 text-right relative"
              variant="none"
              size="sm"
            >
              <span class="when-open">Tutup <b-icon-chevron-up /></span>
              <span class="when-closed">{{ data.item.product.length - 1 }} Produk lainnya <b-icon-chevron-down /></span>
            </b-button>
          </div>
        </div>
      </template>
    </b-table>
    <div
      v-if="loadingTable"
      class="loading-data-order"
    >
      <lottie-animation
        path="animation/loading-data-order.json"
        :width="300"
        :height="300"
      />
    </div>

    <!-- Modal Force -->
    <ModalForce
      ref="modal-force"
      :order-id="orderId"
      status="delivered"
    />
  </div>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BIconSearch,
  BButton,
  BPopover,
  BCollapse,
  VBToggle,
  BIconChevronUp,
  BIconChevronDown,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import secureLs from '@/libs/secureLs'
import { DAY_MONTH_YEAR, TIME, DASH_DATE } from '@/libs/formatDate'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import ModalBundlingQuantity from '@/views/components/modal/ModalBundling.vue'
import { labelClaims, showLabelStatusClaim, claimStatusClass } from '@/libs/label-claims'
import ModalForce from './components/ForceModal.vue'
import banner from './components/Banner.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    LottieAnimation,
    BFormInput,
    BIconSearch,
    BButton,
    BPopover,
    vSelect,
    BIconChevronUp,
    BIconChevronDown,
    flatPickr,
    ModalForce,
    ModalBundlingQuantity,
    banner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    filterItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profile: secureLs.getItem('userData'),
      items: [],
      fields: [
        {
          key: 'order_date',
          label: 'Tanggal Order',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
        {
          key: 'customer_name',
          label: 'Pelanggan',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
        {
          key: 'product',
          label: 'Produk',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
        {
          key: 'grand_total',
          label: 'Total Pembayaran',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
        {
          key: 'airway_bill',
          label: 'No Resi',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
        {
          key: 'details',
          label: 'Rincian',
          thClass: 'align-middle',
          tdClass: 'align-top',
        },
      ],
      loadingTable: false,
      formSearch: null,
      paymentMethod: [],
      productList: this.filterItem.products,
      customerName: [],
      productName: null,
      startDate: null,
      endDate: null,
      addressId: null,
      addressList: this.filterItem.warehouses,
      totalKirim: null,
      totalProblem: null,
      isProblem: false,
      totalFilterDataOrder: 0,
      filterDateDataOrder: false,
      isFilterProduct: false,
      isFilterAddress: false,
      isFilterPayment: false,
      limit: 50,
      offset: 0,
      isLastOrder: false,
      isActive: false,
      orderId: 0,
      DAY_MONTH_YEAR,
      TIME,
      labelClaims,
      showLabelStatusClaim,
      claimStatusClass,
    }
  },
  computed: {
    filterProducts() {
      return this.filterItem.products
    },
    filterWarehouses() {
      return this.filterItem.warehouses
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('main').offsetHeight && !this.loadingTable) {
        this.getNextData()
      }
    }
  },
  mounted() {
    this.getTotalOrder()
    this.getProblemOrder()
    this.fetchData()
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    formatNumber: value => (`${value}`).replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    async fetchData(search) {
      this.loadingTable = true
      this.offset = 0

      const params = {
        order_status: 'Dikirim',
        search,
        product_id: this.productName,
        payment_method: this.paymentMethod,
        start_date: this.startDate,
        end_date: this.endDate,
        is_problem: this.isProblem ? 1 : null,
        limit: this.limit,
        offset: this.offset,
      }

      if (this.addressId !== null) {
        if (this.addressId.is_kompack === 1) {
          Object.assign(params, { warehouse_id: this.addressId.id })
        } else {
          Object.assign(params, { partner_address_id: this.addressId.id })
        }
      }

      await this.$http_komship.get(`v3/order/${this.profile.partner_detail.id}`, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.offset = data.length
          this.loadingTable = false
          if (data.length < this.limit) {
            this.isLastOrder = true
          } else {
            this.isLastOrder = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
          this.loadingTable = false
        })
    },
    getNextData() {
      if (!this.isLastOrder) {
        const paginate = 0
        this.loadingTable = true

        const params = {
          order_status: 'Dikirim',
          customer_name: this.formSearch,
          product_id: this.productName,
          payment_method: this.paymentMethod,
          start_date: this.startDate,
          end_date: this.endDate,
          is_problem: this.isProblem ? 1 : null,
          limit: this.limit,
          offset: this.offset,
        }

        if (this.addressId !== null) {
          if (this.addressId.is_kompack === 1) {
            Object.assign(params, { warehouse_id: this.addressId.id })
          } else {
            Object.assign(params, { partner_address_id: this.addressId.id })
          }
        }

        this.$http_komship.get(`v3/order/${this.profile.partner_detail.id}`, { params })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.loadingTable = false
            if (data.length < this.limit) {
              this.isLastOrder = true
            }
          })
          .catch(err => {
            this.loadingTable = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          })
      }
    },
    getTotalOrder() {
      this.$http_komship.get(`v1/order/count/order-problem/${this.profile.partner_detail.id}`)
        .then(res => {
          const { data } = res.data
          this.totalKirim = data.dikirim
        })
        .catch(err => console.error(err))
    },
    getProblemOrder() {
      this.$http_komship.get(`v1/order/count/card-problem-orders/${this.profile.partner_detail.id}`)
        .then(res => {
          const { data } = res.data
          this.totalProblem = data.order_problem
        })
        .catch(err => console.error(err))
    },
    filterProblem() {
      this.isProblem = !this.isProblem
      this.fetchData()
    },
    resetFilter() {
      this.startDate = null
      this.endDate = null
      this.addressId = null
      this.productName = null
      this.customerName = null
      this.paymentMethod = null
      this.filterDateDataOrder = false
      this.isFilterProduct = false
      this.isFilterAddress = false
      this.isFilterPayment = false
      this.totalFilterDataOrder = 0
      return this.fetchData()
    },
    async copyResi(resi) {
      navigator.clipboard.writeText(resi)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: 'Nomor resi berhasil di copy',
            variant: 'success',
          },
        },
        2000,
      )
    },
    async setPage(totalPage) {
      this.perPage = totalPage
      this.fetchData()
    },
    openTicketing(value) {
      if (value !== null) {
        const routeData = this.$router.resolve({ path: `/ticketing/detail/${value}` })
        window.open(routeData.href, '_blank')
      }
    },
    handleDateTransfer(value) {
      return moment(value).format('DD MMMM YYYY - hh.mm')
    },
    setFilterDate() {
      if (this.startDate !== null) {
        if (this.endDate !== null && !this.filterDateDataOrder) {
          this.totalFilterDataOrder += 1
          this.filterDateDataOrder = true
        }
      }
      if (this.endDate === null) {
        if (this.startDate === null && this.filterDateDataOrder) {
          this.totalFilterDataOrder -= 1
          this.filterDateDataOrder = false
        }
      }
    },
    setFilterProduct() {
      if (this.productName !== null && !this.isFilterProduct) {
        this.isFilterProduct = true
        this.totalFilterDataOrder += 1
      }
      if (this.productName === null) {
        this.totalFilterDataOrder -= 1
        this.isFilterProduct = false
      }
    },
    setFilterAddress() {
      if (this.addressId !== null && !this.isFilterAddress) {
        this.isFilterAddress = true
        this.totalFilterDataOrder += 1
      }
      if (this.addressId === null) {
        this.isFilterAddress = false
        this.totalFilterDataOrder -= 1
      }
    },
    setFilterPayment() {
      if (this.paymentMethod !== null && !this.isFilterPayment) {
        this.isFilterPayment = true
        this.totalFilterDataOrder += 1
      }
      if (this.paymentMethod === null) {
        this.isFilterPayment = false
        this.totalFilterDataOrder -= 1
      }
    },
    fetchIsRetur() {
      this.isRetur = !this.isRetur
      this.fetchData()
    },
    forceDelivered(orderId) {
      this.orderId = orderId
      const params = {
        title: 'Formulir Pengajuan Claim',
        typeClaims: 'Force Delivered',
        ticketId: null,
        orderId: this.orderId,
        typeParams: 'paksa_diterima',
      }
      this.$router.push({ name: 'pengajuan-order', params })
    },
  },
}
</script>
<style>
.form-search {
  padding-left: 40px;
  height: 45px;
  border-radius: 12px;
}

.button-detail {
  font-size: 14px;
  color: #08A0F7 !important;
}

.button-detail:hover {
  color: #c3c3c3 !important;
}

.icon-info {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.buttonCollapse {
  margin-left: -50px;
  width: 130px;
}

.copy-resi {
  margin-left: 2px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.btnPage {
  padding: 4px 7px;
  margin-right: 5px;
}

.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px !important;
  height: 50px !important;
}

.colorActive {
  font-weight: 600;
}

.colorActive:hover {
  color: #F95031;
  font-weight: 600;
}
</style>
